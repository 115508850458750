<template>
<!-- https://bbbootstrap.com/snippets/bootstrap-colorful-navbar-animation-45860002 -->

<ConsulterInforiskClient :isAdmin="true" v-if="isWatchingLogs" @close="closeWatchingLogs"></ConsulterInforiskClient>
<ChangeMyPassword v-if="isChangeMyPasswordVisible" @close="closeChangePassword" @save="saveChangePassword"></ChangeMyPassword>

<nav class="navbar navbar-expand-lg navbar-red navbar-dark">
    <div class="wrapper"> </div>
    <div class="wrapper1"> </div>
    <div class="container-fluid all-show"> 

        <!-- Brand part -->
        <div style="display: block; padding-right:50px; padding-left:10px;">
          <div class="">
            <a class="navbar-brand logoTitle" href="/" style="margin-right:0 !important; ">
              <!-- <div class="flex" style="align-items: center;">
                <div>MY SC</div>
                <div class="dotLogo flex" style="color:red; align-items: center; justify-content: center;">
                  <span class="pi pi-star" style="font-size: 1rem; "></span>
                </div>
                <div>RE</div>
              </div> -->
              <img class="logo" src="../assets/test4.png" />
                <!-- <img class="logo" src="../assets/logo.png" /> -->
            </a>
          </div>
          <!-- <div style="text-align: center; position: absolute;top: 44px;left: 47px;">
            <small style="font-size:1.3rem; color: white;font-family:DancingScript; "> Back-Office </small> &nbsp;&nbsp;
          </div> -->
        </div>
        <!-- Nav items part -->
       
        <ul class="navbar-nav mr-auto mb-2 mb-lg-0">
            <!-- Gestion clients submenu -->
            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="clientDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Gestion clients
                </a>
                <ul class="dropdown-menu" aria-labelledby="clientDropdown">
                    <li>
                        <router-link to="/admin-clients" class="dropdown-item">Gestion clients</router-link>
                    </li>
                    <li>
                        <router-link to="/admin-clientusers" class="dropdown-item">Gestion utilisateurs client</router-link>
                    </li>
                    <li>
                        <router-link to="/admin-clientsettings" class="dropdown-item">Paramétrage clients</router-link>
                    </li>
                </ul>
            </li>

            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="clientDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Gestion admin
                </a>
                <ul class="dropdown-menu" aria-labelledby="clientDropdown">
                    <li>
                        <router-link class="dropdown-item" to="/admin-users">Gestion administrateurs Back-Office</router-link>
                    </li>
                    <li>
                        <router-link class="dropdown-item" to="/admin-mail-templates">Admin Modéles de mail</router-link>
                    </li>
                    <li>
                        <a class="dropdown-item" style="cursor: pointer;" @click.prevent="openWatchingLogs">Voir appels inforisk</a>
                    </li>
                </ul>
            </li>

            <!-- Gestion de données submenu -->
            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="dataDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Gestion référentiel Ompic
                </a>
                <ul class="dropdown-menu" aria-labelledby="dataDropdown">
                    <li>
                        <router-link to="/admin-imports-bilan" class="dropdown-item">Admin Imports Bilan</router-link>
                    </li>
                    <li>
                        <router-link to="/admin-consultation-bilans-ompics" class="dropdown-item">Consultation Bilans Ompic</router-link>
                    </li>

                </ul>
            </li>
        </ul>

            <!-- <div style="width:10%">
              2
              </div> -->
            
            <!-- Logout Part -->
            <div class="topnav-right"> 
              <div class="dropdown" style="min-width:210px">
                <button v-if="currentUser" class="btn dropdown-toggle" style="color:white" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                  <div>{{ currentUser.prenom }} {{ currentUser.nom }}</div>
                 <span style="font-size:0.7rem; color:orange">Administrateur Back-Office</span>
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1" style="font-size: 0.8rem">
                  <li><a class="dropdown-item" style="cursor: pointer;"  @click.prevent="openChangePassword"><i class="pi pi-key"></i> Changer mot de passe</a></li>
                  <li><a class="dropdown-item" style="cursor: pointer;"  @click.prevent="logOut"><font-awesome-icon icon="sign-out-alt" /> Déconnexion</a></li>
                </ul>
              </div>
            </div>
            
    </div>
</nav>


</template>

<script>

import ChangeMyPassword from '../1-common-components/ChangeMyPassword.vue';
import ConsulterInforiskClient from '../1-common-components/ConsulterInforiskClient.vue';

export default {
  name: "HeaderAdmin",
  //--------------------------- Components --------------------------
  components: {
      ChangeMyPassword,
      ConsulterInforiskClient
  },
  //------------------------------ Data -----------------------------
    data() {
        return {
            isChangeMyPasswordVisible: false,
            isWatchingLogs:false
        }
    }, //end data()
  //------------------------------ computed -----------------------------
  computed: {
    
  },
  //--------------------------- Mounted --------------------------
  mounted() {
        this.log("@@@@@@@@@@@@@@"+this.currentUser.application);
        if(this.currentUser.application != "AdminApplication")
        {
          ///alert("Espace non autorisé");
          this.logOut();
        }
  }, //end mounted
  //------------------------------ methods -----------------------------
  methods: {
    logOut() {
      this.$store.dispatch('authStoreModule/logout');
      this.$router.push('/login');
    },
    closeWatchingLogs()
    {
      this.isWatchingLogs = false
    },
    openWatchingLogs: function()
    {
        this.isWatchingLogs = true;
    },
    openChangePassword: function()
    {
        this.isChangeMyPasswordVisible = true;
    },
    closeChangePassword: function()
    {
        this.isChangeMyPasswordVisible = false;
    },
    saveChangePassword: function()
    {
        this.isChangeMyPasswordVisible = false;
        this.$toast.add({severity:'success', summary: 'Opération réussie', detail:"Mot de pass a été réinitialisé avec succès", life: 3000});
    },
  }
};
</script>

<style scoped>

/* @import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap'); */


.logoTitle
{
  font-family: 'Righteous', 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  text-align: center;
  color: #000000;
  font-weight: bold;
}
.logo{
    height: 30px;
    padding-left: 5px;
    margin-top: -8px;
}


/* -------------- */


.navbar-nav>li>a {
    text-transform: uppercase;
    font-size: 14px;
    margin-right: 20px;
    color: #fff
}

.navbar-toggler {
    padding: .20rem .50rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid white
}

.nav-link {
    color: #fff !important;
    cursor: pointer;
    font-weight:bold;
}
.nav-link:hover {
    color: rgb(224, 228, 21) !important;
    font-weight: bold;
    cursor: pointer;
}
.wrapper {
    width: 100%;
    position: absolute;
    height: 100%;
    background-color: #1e415c;
    clip-path: polygon(81% 0, 100% 0, 100% 50%, 100% 100%, 71% 100%);
    transition: 1s all
}

.navbar-brand {
    color: #fff;
    margin-bottom: 4px;
    font-size: 27px
}

.navbar-red:hover .wrapper {
    clip-path: polygon(81% 0, 100% 0, 100% 50%, 100% 100%, 65% 100%)
}

.navbar-brand:hover {
    color: #fff
}

.navbar-red {
    /* background-color: #1e415c; */
    background-color: #d30400;
    color: #fff
}

.all-show {
    z-index: 10
}

.dotLogo {
  height: 25px;
  width: 25px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;

}

.wrapper1 {
    width: 100%;
    position: absolute;
    height: 100%;
    /* background-color: #000; */
    background-color: #1e415c;
    -webkit-clip-path: polygon(0% 0%, 0 100%, 10% 100%, 17% 0);
    clip-path: polygon(0% 0%, 0 100%, 10% 100%, 17% 0);
    transition: 1s all
}

.logo{
    height: 65px;
    padding-left: 5px;
    /* margin-top: -8px; */
}

</style>




